import { createStore } from 'vuex'

export default createStore({
  state: {
    errorMsg: '',
  },
  getters: {
    getErrorMsg: state => state.errorMsg,
  },
  mutations: {
    setErrorMsg(state, value) {
      state.errorMsg = value;
    },
  },
  actions: {
    setErrorMsg({ commit }, value) {
      commit('setErrorMsg', value);
    },
  },
  modules: {
  }
})
